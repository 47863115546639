document.addEventListener("DOMContentLoaded", function() {
	const modalAnswerCover = document.querySelector('.modal-answer-cover');
	const bodyScroll = document.querySelector('body');

	modalAnswerCover.addEventListener('click', function(e) {
		const target = e.target;
		if (target.classList.contains('modal-answer-cover')) {
			modalAnswerCover.classList.remove('modal-answer-cover--open');
			bodyScroll.classList.remove('body-scroll');
		}
		if (target.classList.contains('modal-answer__close')) {
			modalAnswerCover.classList.remove('modal-answer-cover--open');
			bodyScroll.classList.remove('body-scroll');
		}
	});
});