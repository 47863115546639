if (!Array.prototype.forEach) {
	Array.prototype.forEach = function (callback, thisArg) {
		var T, k;
		if (this == null) {
		throw new TypeError(' this is null or not defined');
		}
		var O = Object(this);
		var len = O.length >>> 0;
		if (typeof callback !== 'function') {
			throw new TypeError(callback + ' is not a function');
		}
		if (arguments.length > 1) {
		T = thisArg;
		}
		k = 0;
		while (k < len) {
		var kValue;
		if (k in O) {
			kValue = O[k];
			callback.call(T, kValue, k, O);
		}
		k++;
		}
	};
}
/* ================= END Polifill ============================= */
document.addEventListener('DOMContentLoaded', function() {



	let patternp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
	let patternm = /^[.a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
	let allInputs = document.querySelectorAll('input');
	let allSelect = document.querySelectorAll('select');
	let allCheckBox = document.querySelectorAll('input[type="checkbox"]');
	const bodyScroll = document.querySelector('body');
	let formData;
	let currentform;

	const modalAnswerCover = document.querySelector('.modal-answer-cover');
	const modalAnswerSuccess = document.querySelector('.modal-answer__success');
	const modalAnswerError = document.querySelector('.modal-answer__error');

	let resultValidate = [];
	let inputValidate = false;
	let checkInputMask = false;
	let url = './mail.php';
	let btnForm = document.querySelector('.form__btn');
	let mask3 = new Inputmask({ mask: "+7 (999) 999-99-99",
			oncomplete: function(){
				inputValidate = true;
			},
			onincomplete: function(){
				inputValidate = false;
				console.log(inputValidate);
				document.querySelector('[type="tel"]').classList.add('form__err');
			}
		}).mask(document.querySelectorAll('[type="tel"]'));
	
	btnForm.addEventListener('click', function(e) { // отлавливаем событие клик на кнопке
		e.preventDefault();
		resultValidate = [];
		const target = e.target;
		target.setAttribute('disabled', '');
		// setTimeout(function() {
		// 	target.removeAttribute('disabled');
		// }, 1000);
		currentform = target.closest('form'); // находим текущую форму

		const elemForms = document.forms[0].elements;
		for (let i = 0; i < elemForms.length; i++) {
			const element = elemForms[i];
			checkElementsForm(element);
		}
		for (let j = 0; j < elemForms.length; j++) {
				const element = elemForms[j];
				checkElementsForm(element);
		}
		var checkElem =  resultValidate.every(function(elem) {
			return elem > 0 ? true : false;
		});
		if (checkElem && inputValidate) { // вызываем отправку формы если все элементы прошли валидацию
			sendData(currentform, target, (function(data) {

				
				
				inputValidate = false;
				// console.log(data);
				let response = JSON.parse(data);
				modalAnswerCover.classList.add('modal-answer-cover--open');
				bodyScroll.classList.add('body-scroll');
				for (const [key, value] of Object.entries(response)) {
					modalAnswerError.innerHTML = "";
					modalAnswerSuccess.textContent = "";
					target.removeAttribute('disabled');
					// setTimeout(function() {
					// }, 1000);
					if (+key > 0 & +key <= 4 ) {
						modalAnswerError.innerHTML += `${value} <br/>`;
					} else if(+key === 55) {
						modalAnswerSuccess.textContent = response[55];
					}
				}
			}));
		}

	});
	function sendData (currentForm, btnSubmit, callback) {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url, true); // открываем соединение
		// xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		xhr.addEventListener('readystatechange', function () { // при изменении статуса отправки, вызываем callBack функцию
				if (xhr.readyState !== 4) return;
				if (xhr.readyState === 4 && xhr.status === 200) {
					callback(xhr.response); // вызываем callBack функцию
					currentForm.reset();
				} else {
					console.error(xhr.status, xhr.readyState); // выводим ошибку в консоль
					modalAnswerCover.classList.add('modal-answer-cover--open');
					bodyScroll.classList.add('body-scroll');
					modalAnswerError.innerHTML = 'Что то пошло не так! <br/> Попробуйте чуть позже. <br/>';
					setTimeout(function() {
						btnSubmit.removeAttribute('disabled');
					}, 1000);
				}

		});
		formData = new FormData(currentform);
		formData.set('truck', 'V23JmFL3S7iVTrQstjgFNAP5XDQEsVxIDz2QlvrCK_u74cM9ADpJTH72lWc');
		xhr.send(formData);

	}

	function checkElementsForm(item) {
		if (item.getAttribute('type') !== 'submit') {
			if (item.hasAttribute('data-validate')) {
					if (item.getAttribute('type') === 'text') { // обрабатываем поля с текстом
						if (item.value.trim() === '') {
								item.classList.add('form__err');
								resultValidate.push(0);
							} else {
								resultValidate.push(1);
							}
						}
						if (item.getAttribute('type') === 'tel') { // обрабатывает поля с телефоном
							// let paternResult = patternp.test(item.value.trim());
							if (item.value.trim() === '') { // проверяем поле tel на пустоту
								resultValidate.push(0);  
								item.classList.add('form__err');
							}
						//  else if (!paternResult) { // проверяем корректность номера телефона
						// 		resultValidate.push(0);  
						// } else {
						// 		resultValidate.push(1);  
						// }
					}

					if (item.getAttribute('type') === 'email') { // обрабатываем поля с e-mail
						// let paternResult = patternm.test(item.value.trim());
						if (item.value.trim() === '') { // проверяем поле email на пустоту
								resultValidate.push(0);  
						}
						// else if (!paternResult) { // проверяем корректность e-mail 
						// 		resultValidate.push(0);  
						// } else {
						// 		resultValidate.push(1);  
						// }
					}
					if (item.getAttribute('type') === 'checkbox') { // обрабатываем input:checked
						if (! item.checked) {
								document.querySelector('.form__label-agree').classList.add('form__err-agree');
								resultValidate.push(0);  
						} else {
								resultValidate.push(1);  
						}
					}
					// if (item.tagName === 'SELECT') { // обрабатываем тег select
					// 	if (item.value.trim() === '') {
					// 			let errMsg = "Это обязательное поле";
					// 			resultValidate.push(0);  
					// 	} else {
					// 			resultValidate.push(1);  
					// 	}
					// }
				}
		}
	}

	allCheckBox.forEach(function(checkbox) {
		checkbox.addEventListener('change', function() {
				focuseInput(checkbox);
		});
	});
	allInputs.forEach(function(input) {
		input.addEventListener('focus', function() {
				focuseInput(input);
		});
	});
	// allSelect.forEach(function(select) {
	// 	select.addEventListener('focus', function() {
	// 			focuseInput(select);
	// 	});
	// });
	
	function focuseInput (elem) {
		btnForm.removeAttribute('disabled');
		// elem.style.border = '';
		elem.classList.remove('form__err');
		if (elem.getAttribute('type') === 'checkbox') {
			document.querySelector('.form__label-agree').classList.remove('form__err-agree');
		}
	}


});